import React, { useEffect, useState, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Data from "../../../utils/data";
import Api from "../../../utils/api";
import { useHistory, withRouter, Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import NumberFormat from "react-number-format";
import "slick-carousel/slick/slick.css";
import Breadcrumb from "../../../Layouts/Breadcrumb";
import WithContext from "../../../Layouts/MainMessage/WithContext";
import MetaTags from "react-meta-tags";
import { CartContext } from "../../../Layouts/CartContextProvider";
import { useInput } from "../../../hooks/inputHook";
import LoadingOverlay from "react-loading-overlay";
import storage from "../../../utils/storage";

import { CART, ORDER_COMPLETE } from "../../../route/routeURL";
import Modal from "../../../Layouts/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cartFunction from "../../../utils/cartFunction";
import CheckoutSkeleton from "../../Skeleton/CheckoutSkeleton";
const CheckoutFlexiPay = (props) => {
  const { addToCartApi } = useContext(CartContext);

  const [carts, setCarts] = useState(null);

  const [cartData, setCartData] = useState({});
  let history = useHistory();
  const [product, setProduct] = useState([]);
  const [meta, setMeta] = useState({});
  const [title, setTitle] = useState("");
  const [emi_price, setEmiPrice] = useState(0);
  const [period, setPeriod] = useState(0);
  const [month, setMonth] = useState("");
  const [bank, setBank] = useState({});
  const [banks, setBanks] = useState([]);
  const [validateError, setValidateError] = useState("");
  const [message, setMessage] = useState("");
  const [emiPeriods, setEmiPeriods] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  const [isActiveOverlay, setIsActiveOverlay] = useState(false);
  const {
    value: provinceId,
    bind: bindProvinceId,
    setValue: setProvinceId,
  } = useInput("");
  const { value: cityId, bind: bindCityId, setValue: setCityId } = useInput("");
  const { value: areaId, bind: bindAreaId, setValue: setAreaId } = useInput("");

  const { value: name, bind: bindName, setValue: setName } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
  const { value: mobile, bind: bindMobile, setValue: setMobile } = useInput("");
  const {
    value: address,
    bind: bindAddress,
    setValue: setAddress,
  } = useInput("");
  const {
    value: customer_note,
    bind: bindCustomerNote,
    setValue: setCustomerNote,
  } = useInput("");
  const {
    value: cardHolderName,
    bind: bindCardHolderName,
    setValue: setCardHolderName,
  } = useInput("");
  const {
    value: cardHolderEmail,
    bind: bindCardHolderEmail,
    setValue: setCardHolderEmail,
  } = useInput("");
  const {
    value: cardHolderMobile,
    bind: bindCardHolderMobile,
    setValue: setCardHolderMobile,
  } = useInput("");
  const {
    value: firstSix,
    bind: bindFirstSix,
    setValue: setFirstSix,
  } = useInput("");
  const {
    value: expiryDate,
    bind: bindExpiryDate,
    setValue: setExpiryDate,
  } = useInput("");

  const {
    value: cardHolderAddress,
    bind: bindCardHolderAddress,
    setValue: setCardHolderAddress,
  } = useInput("");
  const { value: bankId, bind: bindBankId, setValue: setBankId } = useInput("");
  const {
    value: emiPeriod,
    bind: bindEmiPeriod,
    setValue: setEmiPeriod,
  } = useInput("");

  async function fetchCarts() {
    var items = await cartFunction.checkOutFlexiPay();
    console.log(items);
    if (items) {
      if (storage.isLoggedIn()) {
        let user = storage.getUser();
        // setName(user.name);
        // setEmail(user.email);
        // setMobile(user.mobile);
        // setAddress(user.address);
      }
      setCarts(items.data.cart_items);
      items.data.payment_options = items.data.payment_options.reverse();
      setCartData(items.data);
    } else {
      setCarts([]);
    }
    setIsloading(false);
  }
  async function getProvince() {
    fetch(Data.API_BASE_URL + "get-provinces", {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function getCities(e) {
    setAreas([]);
    let provinceId = e.target.value;
    if (provinceId !== null && provinceId !== "") {
      setProvinceId(e.target.value);
      fetch(Data.API_BASE_URL + "get-cities-via-province/" + provinceId, {
        method: "GET",
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          setCities(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setProvinceId("");
      setCityId("");
      setCities([]);
    }
  }

  async function getAreas(e) {
    let cityId = e.target.value;
    if (cityId !== null && cityId !== "") {
      setCityId(e.target.value);
      fetch(Data.API_BASE_URL + "get-areas-via-city/" + cityId, {
        method: "GET",
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCityId("");
      setAreas([]);
    }
  }
  async function getBanks() {
    fetch(Data.API_BASE_URL + "get-emi-banks-by-user", {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response.data);
        setMessage(response.message);

        setBanks(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function getGetProductInfo(bankId, emiPeriod) {
    findMonth(bankId, emiPeriod).then(
      () => {
        setIsActiveOverlay(false);
      }

      // setEmiPrice(cartData)
    );

    // fetch(Data.API_BASE_URL + "get-emi-banks/" + "1" + "/bank/" + bankId + "/month/" + emiPeriod, {
    //     method: "GET",
    //     headers: Data.API_HEADER,
    // })
    //     .then(Api.handleResponseAndError)
    //     .then((response) => {
    //         setEmiPrice(response.data.product.emi_price)
    //         findMonth(bankId, emiPeriod);
    //         setIsActiveOverlay(false);

    //     })
    //     .catch((error) => {
    //         setIsActiveOverlay(false);
    //         console.log(error);
    //     });
  }
  function OnChangeBank(e) {
    let bankId = e.target.value;
    if (bankId !== null && bankId !== "") {
      setBankId(e.target.value);
      const emiBanks = banks.find((bank) => bank.id == bankId).emi_periods;
      console.log(emiBanks);
      setEmiPeriods(emiBanks);
      if (emiPeriod !== null && emiPeriod !== "") {
        setIsActiveOverlay(true);
        setEmiPeriod(emiPeriod);
        getGetProductInfo(bankId, emiPeriod);
        if (storage.isLoggedIn()) {
          let user = storage.getUser();
          setName(user.name);
          setEmail(user.email);
          setMobile(user.mobile);
          setAddress(user.address);
        }
      }
    } else {
      setBankId("");
      setEmiPeriods([]);
    }
  }
  function OnChangeEmiPeriod(e) {
    let emiPeriod = e.target.value;
    console.log(emiPeriod);
    if (emiPeriod !== null && emiPeriod !== "") {
      setIsActiveOverlay(true);
      setEmiPeriod(e.target.value);
      getGetProductInfo(bankId, e.target.value);
      if (storage.isLoggedIn()) {
        let user = storage.getUser();
        setName(user.name);
        setEmail(user.email);
        setMobile(user.mobile);
        setAddress(user.address);
      }
    } else {
      setEmiPeriod(null);
    }
  }
  async function findMonth(bankId, emiPeriod) {
    const emiBanks = banks.find((bank) => bank.id == bankId).emi_periods;
    const bank = banks.find((bank) => bank.id == bankId);
    setBank(bank);
    console.log(bank);
    const period = emiBanks.find((emiBank) => emiBank.id == emiPeriod).name;
    const month = emiBanks.find((emiBank) => emiBank.id == emiPeriod).months;
    setEmiPrice((cartData.grand_total / month).toFixed(2));
    setMonth(period);
    setPeriod(emiBanks.find((emiBank) => emiBank.id == emiPeriod));
  }

  function setProductData(data) {
    setTitle(data["title"]);
    setProduct(data["product"]);
    setMeta(data["meta"]);
    setIsloading(false);
  }
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsActiveOverlay(true);
    let url = new URL(Data.API_BASE_URL + "post-checkout-flexipay");
    url.search = new URLSearchParams({
      platform: "W",
    }).toString();
    fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        shipping_data: {
          name: name,
          email: email,
          address: address,
          mobile: mobile,
        },
        account_holder_detail: {
          bank_id: bankId,
          account_holder: cardHolderName,
          account_holder_address: cardHolderAddress,
          account_holder_email: cardHolderEmail,
          account_holder_mobile: cardHolderMobile,
          first_six_card_number: firstSix,
          expiry_date: expiryDate,
        },
        product_id: product.id,
        emi_duration_id: period.id,
        duration: period.months,
        area_id: areaId,
        city_id: cityId,
        province_id: provinceId,
        customer_note: customer_note, 
      }),
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode == 200) {
          window.location = response.payment_gateway_url;
          // history.push(ORDER_COMPLETE + "?order=completed&emi-purchase=1");
        } else {
          setIsActiveOverlay(false);
          setValidateError(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    evt.preventDefault();
  };
  useEffect(() => {
    setIsloading(true);
    if (!storage.isLoggedIn()) {
      history.replace("/user/login");
    }
    fetchCarts();
    getProvince();
    getBanks();
  }, [props.match.params]);

  return (
    <React.Fragment>
      {isLoading ? (
        <CheckoutSkeleton />
      ) : (
        <React.Fragment>
          <Breadcrumb
            levelOne={{
              name: "Checkout",
            }}
            levelTwo={{
              name: "Checkout with Flexi Pay",
            }}
          />

          <MetaTags>
            <title>Checkout with Flex pay</title>
          </MetaTags>
          <section className="pdp-section shown">
            <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
              <div className="row background-theme-white">
                {banks.length === 0 ? (
                  <div className="col-lg-12">
                    <h4 className="text-center" style={{ margin: "50px" }}>
                      {message}
                      <br />
                      <br />
                      <small>
                        <Link
                          to={{
                            pathname: CART,
                          }}
                        >
                          Go to Cart
                        </Link>
                      </small>
                    </h4>
                  </div>
                ) : (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      acceptCharset="UTF-8"
                      id="checkout-form"
                      className="ng-pristine ng-valid"
                    >
                      <div className="col-md-6 col-xs-12 ">
                        <input
                          name="_token"
                          type="hidden"
                          value="grVSBtn6dMd709n4Ac6bpLBIt2isB87cBatHmEa2"
                          autoComplete="off"
                        />

                        <div className="page-content checkout-page">
                          <div className="box-border">
                            <div className="row">
                              <div
                                className="col-lg-12"
                                id="shipping_addr_block"
                              >
                                <h3 className="brand-color">
                                  Easy buy easy pay
                                </h3>
                                <div>
                                  FLEXI PAY EMI is one of the payment options
                                  available on CG|Digital which allows you to
                                  convert your credit card payment to easy
                                  monthly instalments. You can purchase our
                                  products at 0% Interest and 0% Downpayment.
                                  EMI conversions are at the discretion of your
                                  credit card issuing bank. Please contact your
                                  issuing bank for any clarifications. EMI
                                  processing fee is applicable by the bank. The
                                  processing fee may vary depending upon the
                                  bank.
                                </div>
                                <h3 className="brand-color">
                                  Self Declaration
                                </h3>
                                <div>
                                  <ol style={{ marginLeft: "-25px" }}>
                                    <li>
                                      The applicant must use his/her own Credit
                                      Card. Proxy Application or applying for
                                      EMI with other’s Credit Card is not
                                      applicable.
                                    </li>
                                    <li>
                                      The applicant must not have previous EMI
                                      dues with the Credit Card s/he is
                                      applyiing with.
                                    </li>
                                    <li>
                                      The applicant must not have a history of
                                      default concerning any matters realated to
                                      the card that the bank considers a
                                      default.
                                    </li>
                                    <li>
                                      The final decision to convert your credit
                                      card transaction to EMI is upon your card
                                      issuing bank.
                                    </li>
                                  </ol>
                                </div>
                                <h3>Bank Detail</h3>
                                <div>
                                  <br />
                                  <label>
                                    For EMI You need to have account/credit card
                                    on one of our partner bank.
                                  </label>
                                  <br />
                                  <label>
                                    Select your prefered bank{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <select
                                    className="select2 form-control input select2-hidden-accessible"
                                    name="bank"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    required
                                    {...bindBankId}
                                    onChange={OnChangeBank}
                                  >
                                    <option value="" defaultValue>
                                      Choose bank
                                    </option>
                                    {banks.map(function (bank, index) {
                                      return (
                                        <option value={bank.id} key={index}>
                                          {bank.bank_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>
                                    Emi Period{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <select
                                    className="select2 form-control input select2-hidden-accessible"
                                    name="emi_period"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    required
                                    {...bindEmiPeriod}
                                    onChange={OnChangeEmiPeriod}
                                  >
                                    <option value="" defaultValue>
                                      Choose Period
                                    </option>
                                    {emiPeriods.map(function (
                                      emiPeriod,
                                      index
                                    ) {
                                      return (
                                        <option
                                          value={emiPeriod.id}
                                          key={index}
                                        >
                                          {emiPeriod.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <span className="error-message"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {emi_price ? (
                          <div className="box-border">
                            <div className="row">
                              <div
                                className="col-lg-12"
                                id="shipping_addr_block"
                              >
                                <h3>Other Bank Information</h3>
                                <div>
                                  <label>
                                    Account Holder Name{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="name"
                                    type="text"
                                    {...bindCardHolderName}
                                    placeholder="Enter Card Holder Name"
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>
                                    Account Holder Address{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Card Holder Address"
                                    {...bindCardHolderAddress}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>

                                <div>
                                  <label>
                                    Account Holder Mobile Number
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="mobile"
                                    type="number"
                                    placeholder="Enter Card Holder Mobile Number"
                                    {...bindCardHolderMobile}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>
                                    Account Holder Email{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="email"
                                    type="email"
                                    placeholder="Enter Card Holder Email"
                                    {...bindCardHolderEmail}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>
                                <label>Enter First Six card number</label>
                                <div>
                                  <input
                                    className="form-control input"
                                    name="first_four"
                                    type="number"
                                    placeholder="First Six Digit"
                                    max="999999"
                                    min="1"
                                    {...bindFirstSix}
                                    required
                                  />

                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>
                                    Account Holder Card Expiry Date{" "}
                                    <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="text"
                                    type="text"
                                    placeholder="MM\YY"
                                    {...bindExpiryDate}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>

                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div></div>

                                  <label for="male">
                                    {" "}
                                    <input
                                      type="checkbox"
                                      name="terms_condition"
                                      style={{ marginRight: "10px" }}
                                    />
                                    I hereby declare that i have read and
                                    understood terms and conditions of (Bank)
                                    mentioned overleaf governing installment
                                    product and credit card I agree to abide by
                                    these rule{" "}
                                    <a
                                      href="#storeConsultation"
                                      data-toggle="modal"
                                      data-target="#storeConsultation"
                                    >
                                      read more
                                    </a>
                                  </label>
                                  <br />
                                </div>
                                {validateError != "" ? (
                                  <span
                                    className="error-message"
                                    style={{ color: "red", fontSize: "13px" }}
                                  >
                                    <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                    {validateError}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <br />
                          </div>
                        ) : (
                          " "
                        )}
                      </div>

                      <div className="col-md-6 col-xs-12 ">
                        <div className="sidebar-bar-title">
                          <h3>Product Summary</h3>
                        </div>
                        <div className="block-content">
                          <ul
                            id="cart-sidebar"
                            className="mini-products-list"
                            style={{ maxHeight: "none" }}
                          >
                            {carts.map(function (product, index) {
                              return (
                                <li className="item odd" key={index}>
                                  <Link
                                    to={
                                      "/product-" +
                                      product.product_name
                                        .replace(/[#+. _/]/gi, "-")
                                        .toLowerCase() +
                                      "-" +
                                      product.product_id
                                    }
                                    title="Chimney"
                                    className="product-image"
                                  >
                                    <img
                                      src={product.product_img}
                                      alt={product.product_name}
                                      width="65"
                                    />
                                  </Link>
                                  <div className="product-details">
                                    <p className="product-name">
                                      <Link
                                        to={
                                          "/product-" +
                                          product.product_name
                                            .replace(/[#+. _/]/gi, "-")
                                            .toLowerCase() +
                                          "-" +
                                          product.product_id
                                        }
                                      >
                                        {product.product_name}
                                      </Link>
                                    </p>
                                    {product.free != null ? (
                                      <p style={{ fontSize: "12px" }}>
                                        <span
                                          style={{
                                            color: "rgb(236, 183, 19)",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Free:
                                        </span>
                                        {product.free.name}
                                        (
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          prefix={"NPR "}
                                          value={product.free.price}
                                        />
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <strong>{product.qty}</strong> x{" "}
                                    <span className="price">
                                      {product.coupon_discount != null ? (
                                        <React.Fragment>
                                          {product.coupon_discount.option ==
                                          "P" ? (
                                            <NumberFormat
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandsGroupStyle="lakh"
                                              prefix={"NPR "}
                                              value={product.total}
                                            />
                                          ) : (
                                            <NumberFormat
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandsGroupStyle="lakh"
                                              prefix={"NPR "}
                                              value={product.total}
                                            />
                                          )}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {product.product_discount_price !=
                                          null ? (
                                            <NumberFormat
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandsGroupStyle="lakh"
                                              prefix={"NPR "}
                                              value={
                                                product.product_discount_price
                                              }
                                            />
                                          ) : (
                                            <NumberFormat
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandsGroupStyle="lakh"
                                              prefix={"NPR "}
                                              value={product.product_price}
                                            />
                                          )}
                                        </React.Fragment>
                                      )}
                                    </span>{" "}
                                    {product.coupon_discount != null ? (
                                      <React.Fragment>
                                        <span className="cart-app-rights">
                                          <i className="fa fa-check"></i>(Coupon
                                          Code Applied :{" "}
                                        </span>
                                        {product.coupon_discount.option ==
                                        "P" ? (
                                          <span className="cart-app-rights">
                                            {product.coupon_discount.coupon}
                                          </span>
                                        ) : (
                                          <span className="cart-app-rights">
                                            Discount NPR{" "}
                                            {product.coupon_discount.coupon}
                                          </span>
                                        )}
                                        )
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          {emiPeriod ? (
                            <>
                              <div className="top-subtotal">
                                <span>
                                  {" "}
                                  <img
                                    src={bank.bank_logo + "?" + Date.now()}
                                    height="50px"
                                  />
                                </span>
                                <br />
                              </div>

                              <div className="top-subtotal">
                                Monthly Installment:{" "}
                                <span>
                                  {" "}
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    prefix={"NPR "}
                                    value={emi_price}
                                  />
                                </span>
                                <br />
                              </div>
                              <div className="top-subtotal">
                                Emi Period:
                                <span> {month}</span>
                                <br />
                              </div>
                              <div className="top-subtotal">
                                Grand Total:{" "}
                                <span className="price">
                                  {" "}
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    prefix={"NPR "}
                                    value={cartData.grand_total}
                                  />
                                </span>
                                <br />
                              </div>
                            </>
                          ) : (
                            <div className="top-subtotal">
                              Set Bank and Period
                            </div>
                          )}
                        </div>

                        {emi_price ? (
                          <div className="box-border">
                            <div className="row">
                              <div
                                className="col-lg-12"
                                id="shipping_addr_block"
                              >
                                <h3>Shipping Detail</h3>
                                <div>
                                  <label>
                                    Name <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="name"
                                    type="text"
                                    {...bindName}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>

                                <div>
                                  <label>
                                    Mobile <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="mobile"
                                    type="text"
                                    {...bindMobile}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>
                                    Email <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="email"
                                    type="text"
                                    {...bindEmail}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>
                                    Province <span className="required">*</span>
                                  </label>
                                  <select
                                    id="shippingAddrProvinceInput"
                                    className="select2 form-control input select2-hidden-accessible"
                                    name="province"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    required
                                    {...bindProvinceId}
                                    onChange={(e) => {
                                      getCities(e);
                                    }}
                                  >
                                    <option value="" defaultValue>
                                      Choose Province
                                    </option>
                                    {provinces.map(function (province, index) {
                                      return (
                                        <option value={province.id} key={index}>
                                          {province.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div>
                                  <label>
                                    City <span className="required">*</span>
                                  </label>
                                  <select
                                    id="shippingAddrCityInput"
                                    className="select2 form-control input select2-hidden-accessible"
                                    name="city"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    required
                                    {...bindCityId}
                                    onChange={(e) => {
                                      getAreas(e);
                                    }}
                                  >
                                    <option value="" defaultValue>
                                      Choose City
                                    </option>
                                    {cities.map(function (city, index) {
                                      return (
                                        <option value={city.id} key={index}>
                                          {city.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div>
                                  <label>
                                    Area <span className="required">*</span>
                                  </label>
                                  <select
                                    id="shippingAddrAreaInput"
                                    className="select2 form-control input select2-hidden-accessible"
                                    name="area"
                                    tabIndex="-1"
                                    aria-hidden="true"
                                    required
                                    {...bindAreaId}
                                  >
                                    <option value="" defaultValue>
                                      Choose Area
                                    </option>
                                    {areas.map(function (area, index) {
                                      return (
                                        <option value={area.id} key={index}>
                                          {area.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div>
                                  <label>
                                    Address <span className="required">*</span>
                                  </label>
                                  <input
                                    className="form-control input"
                                    name="name"
                                    type="text"
                                    {...bindAddress}
                                    required
                                  />
                                  <span className="error-message"></span>
                                </div>
                                <div>
                                  <label>Customer Note</label>
                                  <input
                                    style={{ height: "100px", width: "100%" }}
                                    name="customer_note"
                                    type="text"
                                    {...bindCustomerNote}
                                  />
                                </div>
                                <br />
                                <div style={{ marginBottom: "10px" }}>
                                  <button type="submit" className="button ">
                                    <i className="fa fa-angle-double-right"></i>
                                    &nbsp; <span>Complete Emi Checkout</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </form>
                  </>
                )}
              </div>

              <Modal
                dataSection="large"
                id="storeConsultation"
                body={
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: bank.terms_and_condition,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                }
                heading={"Terms and Conditions"}
              />
            </LoadingOverlay>
          </section>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default withRouter(WithContext(CheckoutFlexiPay));
